import React, { useRef, createRef, useEffect, useContext } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Project from '../components/Project'
import { Container } from 'react-grid-system'
import projects from '../config/projects'
import styled from 'styled-components'
import Header from '../components/Header'
import gsap from 'gsap/gsap-core'
import { up } from 'styled-breakpoints'
import { LoaderContext } from '../context/loader';

const Projects = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  ${up('lg')} {
    height: calc(100vh - 45px - 20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const Socials = styled.div`
  margin-top: -60px;
  padding-bottom: 30px;
  z-index: 1;
  width: 100%;
  opacity: 0;

  ${up('lg')} {
    position: fixed;
    bottom: 0;
    right: 30px;
  }
`

const Social = styled.a`
  color: black;
  text-decoration: none;
  display: block;
  text-align: right;
  transition: color 0.4s ${props => props.theme.eases.out};
  width: min-content;
  margin-left: auto;

  &:hover { color: ${props => props.theme.colors.red}; }
`

const IndexPage = () => {
  const projectsRefs = useRef([])
  const currentProjectIndex = useRef(-1)
  const projectsLength = projects.length
  const isEnter = useRef(false)
  const header = useRef()
  const socials = useRef()
  const hasLoaderEvent = useRef(false)
  const context = useContext(LoaderContext)

  useEffect(() => {
    if(!context.loading) setTimeout(appear, 200)
    else {
      hasLoaderEvent.current = true
      window.addEventListener('loader-end', appear)
    }

    return () => {
      if (hasLoaderEvent.current) window.removeEventListener('loader-end', appear)
    }
  })

  const appear = () => {
    projectsRefs.current.forEach((aProject, index) => {
      aProject.current.appear(index * 0.075)
    })
    gsap.to(socials.current, { delay: 1, alpha: 1, duration: 0.4 })
  }

  if (projectsRefs.current.length !== projectsLength) {
    projectsRefs.current = Array(projectsLength).fill().map((_, i) => projectsRefs.current[i] || createRef());
  }

  const onMouseEnter = (e) => {
    currentProjectIndex.current = Number(e.currentTarget.dataset.index)

    header.current.hideLink()
    gsap.to(socials.current, {
      alpha: 0,
      duration: 0.2
    })

    projectsRefs.current.forEach((aProject, index) => {
      if (index === currentProjectIndex.current) aProject.current.show()
      else aProject.current.hide()
    })

    isEnter.current = true
  }

  const onMouseLeave = (e) => {
    isEnter.current = false
    currentProjectIndex.current = -1

    setTimeout(() => !isEnter.current && projectsRefs.current.forEach((aProject) => {
      aProject.current.show()
      header.current.showLink()
      gsap.to(socials.current, {
        delay: 0.2,
        alpha: 1,
        duration: 0.4
      })
    }), 0)
  }

  return(
    <Layout>
      <Header ref={header}/>
      <Seo title='Edwin Contat | Digital Art Director & Graphic Designer' />
      <Container fluid>
        <Projects>
          {projects.map((project, i) => {
            return(<Project key={i} index={i} mouseLeave={onMouseLeave} mouseEnter={onMouseEnter} ref={projectsRefs.current[i]} title={project.title} date={project.date} company={project.company} url={project.url} images={project.images} skills={project.skills}/>)
          })}
        </Projects>
      </Container>
      <Container fluid>
        <Socials ref={socials}>
          <Social href="https://www.instagram.com/edwin.contat.design/" rel='noopener' target='_blank'>Instagram</Social>
          <Social href="https://twitter.com/EdwinContat" rel='noopener' target='_blank'>Twitter</Social>
          <Social href="https://dribbble.com/EdwinContat" rel='noopener' target='_blank'>Dribbble</Social>
          <Social href="https://www.behance.net/edwincontat" rel='noopener' target='_blank'>Behance</Social>
          <Social href="https://www.linkedin.com/in/edwin-contat/" rel='noopener' target='_blank'>Linkedin</Social>
        </Socials>
      </Container>
    </Layout>
  )
}

export default IndexPage
