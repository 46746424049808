import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import styled from 'styled-components'
import { Link } from 'gatsby'
import gsap from 'gsap'

const Title = styled.h1`
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
`

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  transition: color 0.4s ${props => props.theme.eases.out};

  &:hover { color: ${props => props.theme.colors.red}; }
`

const Header = forwardRef((props, ref) => {
  const link = useRef()

  useImperativeHandle(ref, () => ({
    hideLink: () => {
      gsap.to(link.current, {
        alpha: 0,
        duration: 0.2
      })
    },
    showLink: () => {
      gsap.to(link.current, {
        delay: 0.2,
        alpha: 1,
        duration: 0.4
      })
    }
  }))

  return (
    <header style={{paddingTop: '30px'}}>
      <Container fluid>
        <Row justify='between'>
          <Col xs='content'><Title className='f12'>Edwin contat</Title></Col>
          <Col xs='content'><StyledLink to='/about' ref={link}>Info, Contact</StyledLink></Col>
        </Row>
      </Container>
    </header>
  )
})

export default Header
