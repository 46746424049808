import React, { useImperativeHandle, forwardRef, useRef, useContext, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Container, Row, Col, Visible } from 'react-grid-system'
import { up } from 'styled-breakpoints'
import gsap from 'gsap'
import Img from 'gatsby-image'
import globalVariables from '../config/globalVariables'

const Link = styled.a`
  color: black;
  text-decoration: none;
  color: black;
`

const DateCompany = styled.div`
  opacity: 0;
`

const StyledTitle = styled.h2`
  color: black;
  opacity: 0;
`

const ImagesWrapper = styled.div`
  width: 100%;
  
  ${up('lg')} {
    position: fixed;
    left: 50%;
    top: 0;
    width: 50vw;
    height: 100vh;
    z-index: 10;
    pointer-events: none;
  }
`

const Image = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 131%;
  overflow: hidden;
  
  ${up('lg')} {
    padding-bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;

    ${up('lg')} {
      opacity: 0;
      transform: scale(1.2);
    }
  }
`

const Skills = styled.div`
  color: ${props => props.theme.colors.red};
  margin-bottom: 50px;

  ${up('lg')} {
    margin-bottom: 0;
    position: fixed;
    pointer-events: none;
    left: 0;
    z-index: 15;
    width: 100%;
    text-align: right;
    top: 30px;
    mix-blend-mode: difference;
    opacity: 0;
  }
`

const Project = forwardRef((props, ref) => {
  const title = useRef()
  const titleRect = useRef()
  const images = useRef()
  const imageIndex = useRef(0)
  const imageRefs = useRef()
  const themeContext = useContext(ThemeContext)
  const mouse = useRef({difference: 0, x: 0, y: 0})
  const canUpdateImage = useRef(false)
  const skills = useRef()
  const company = useRef()
  const date = useRef()
  const canInteract = useRef(false)

  useImperativeHandle(ref, () => ({
    hide: () => {
      gsap.to(title.current, {
        alpha: 0.2,
        duration: 0.4,
        ease: 'power2.out'
      })
    },
    appear: (delay = 0) => {
      gsap.to(title.current, {
        delay,
        color: '#d0021b',
        duration: 1,
        ease: 'power2.out',
        onComplete: () => { canInteract.current = true }
      })
      gsap.to([company.current, date.current, title.current], {
        delay,
        alpha: 1,
        duration: 1,
        ease: 'power2.out'
      })
    },
    show: (delay = 0) => {
      gsap.to(title.current, {
        delay,
        alpha: 1,
        duration: 0.4,
        ease: 'power2.out'
      })
    }
  }))

  useEffect(() => {
    images.current = imageRefs.current.querySelectorAll('.gatsby-image-wrapper')
    titleRect.current = title.current.getBoundingClientRect()
    canUpdateImage.current = images.current.length > 1
  })

  const onMouseEnter = (e) => {
    if (!titleRect.current || globalVariables.isMobile || !canInteract.current) return

    if (canUpdateImage.current) {
      mouse.current.x = e.pageX - titleRect.current.x
      mouse.current.y = e.pageY - titleRect.current.y
      mouse.current.difference = 0

      if(imageIndex.current > 0) {
        gsap.set(images.current[imageIndex.current], { alpha: 0, scale: 1.0 })

        imageIndex.current = 0
      }
    }

    setAlpha([imageRefs.current, skills.current], 1)
    showImage(images.current[imageIndex.current])

    props.mouseEnter && props.mouseEnter(e)
  }

  const setAlpha = (el, alpha) => {
    gsap.to(el, {
      alpha,
      duration: 0.4,
      ease: 'power2.out'
    })
  }

  const updateImage = () => {
    hideImage(images.current[imageIndex.current])

    imageIndex.current = imageIndex.current + 1 < images.current.length ? imageIndex.current + 1 : 0

    showImage(images.current[imageIndex.current])
  }

  const hideImage = (image) => {
    gsap.fromTo(image, {
      alpha: 1,
      scale: 1.1
    }, {
      alpha: 0,
      scale: 1.0,
      duration: 0.4,
      ease: 'power2.out'
    })
  }

  const showImage = (image) => {
    gsap.fromTo(image, {
      alpha: 0,
      scale: 1.2
    }, {
      alpha: 1,
      scale: 1.1,
      duration: 0.4,
      ease: 'power2.out'
    })
  }

  const onMouseMove = (e) =>  {
    if (!titleRect.current || !canUpdateImage.current || globalVariables.isMobile || !canInteract.current) return

    const newX = e.pageX - titleRect.current.x
    const newY = e.pageY - titleRect.current.y

    mouse.current.difference += Math.abs(mouse.current.x - newX)
    mouse.current.difference += Math.abs(mouse.current.y - newY)

    if (mouse.current.difference >= 40) {
      updateImage()
      mouse.current.difference = 0
    }

    mouse.current.x = newX
    mouse.current.y = newY
  }

  const onMouseLeave = () => {
    if (globalVariables.isMobile || !canInteract.current) return
    setAlpha([imageRefs.current, skills.current], 0)
    
    props.mouseLeave && props.mouseLeave()
  }

  return (
    <Row>
      <Visible xs sm md>
        <Col lg={6}>
          <ImagesWrapper>
            <Image ref={imageRefs}>
              <Img fluid={themeContext.images[`${props.images[0]}`].fluid} alt={`Projet ${props.title}`}/>
            </Image>
          </ImagesWrapper>
        </Col>
      </Visible>
      <Col lg={8}>
        <Row align='end'>
          <Col xs={8} lg='content'><Link href={props.url} target='_blank' rel='noopener' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove} data-index={props.index}><StyledTitle ref={title} className='title'>{props.title}</StyledTitle></Link></Col>
          <Col style={{ marginBottom: '13px' }}>
            <Row><Col lg='content'><DateCompany ref={date}>{props.date}</DateCompany></Col></Row>
            <Row><Col lg='content' style={{ textTransform: 'uppercase'}}><DateCompany ref={company}>{props.company}</DateCompany></Col></Row>
          </Col>
          <Visible xs sm md><Col lg='content'><Skills ref={skills}>{props.skills.map((skill, i) => (<div key={`skill-${i}`}>{skill}</div>))}</Skills></Col></Visible>
        </Row>
      </Col>
      <Visible lg xl xxl>
        <Col lg={4}>
          <Skills ref={skills}><Container fluid>{props.skills.map((skill, i) => (<div key={`skill-${i}`}>{skill}</div>))}</Container></Skills>
          <ImagesWrapper>
            <Image ref={imageRefs}>
              {props.images && props.images.map((imageName, i) => {
                if (themeContext.images[`${imageName}`]) return (<Img key={i} fluid={themeContext.images[`${imageName}`].fluid} alt={`Projet ${props.title}`}/>)
                else return null
              })}
            </Image>
          </ImagesWrapper>
        </Col>
      </Visible>
    </Row>
  )
})

export default Project