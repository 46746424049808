const projects = [
  {
    'title': 'Craie Craie',
    'date': '2021',
    'company': 'Freelance',
    'url': 'https://craiecraie.com',
    'skills': [
      'Art direction',
      'Interactive Design',
      'Motion',
      '3D Design',
    ],
    'images': [
      'CRA',
      'CRA-1',
      'CRA-2',
      'CRA-3',
      'CRA-4',
    ]
  },
  {
    'title': 'Compose.it',
    'date': 'Ongoing',
    'company': 'Freelance',
    'url': 'https://composeitprod.fr',
    'skills': [
      'Art direction',
      'Branding & Logo',
      'Motion',
      'Interactive Design'
    ],
    'images': [
      'CIT'
    ]
  },
  {
    'title': 'Le Petit Salon',
    'date': '2020',
    'company': 'Employee at Digital Cover',
    'url': 'https://lpslyon.fr',
    'skills': [
      'Art direction',
      'Interactive Design',
      'Motion'
    ],
    'images': [
      'LPS',
      'LPS-1',
      'LPS-2',
      'LPS-3',
      'LPS-4'
    ]
  },
  {
    'title': 'EMLyon Junior Conseil',
    'date': '2019',
    'company': 'Employee at Digital Cover',
    'url': 'https://emlyonjuniorconseil.com',
    'skills': [
      'Art direction',
      'Interactive Design',
      'Motion'
    ],
    'images': [
      'EML',
      'EML-1',
      'EML-2',
      'EML-3',
      'EML-4',
    ]
  },
  {
    'title': 'MTG Interieur',
    'date': '2020',
    'company': 'Employee at Digital Cover',
    'url': 'https://mtg-interieur.fr',
    'skills': [
      'Art direction',
      'Interactive Design',
      'Motion'
    ],
    'images': [
      'MTG',
      'MTG-1',
      'MTG-2',
      'MTG-3',
      'MTG-4',
    ]
  },
  {
    'title': 'Track I Discover',
    'date': 'Ongoing',
    'company': 'Freelance',
    'url': '',
    'skills': [
      'Art direction',
      'Branding & Logo',
      'Interactive Design',
      'Motion',
      '3D Design',
    ],
    'images': [
      'TID',
      'TID-1',
      'TID-2',
      'TID-3',
      'TID-4',
      'TID-5',
      'TID-6	',
    ]
  },
  {
    'title': 'Benkei',
    'date': '2020',
    'company': 'Employee at Digital Cover',
    'url': 'https://www.benkei.fr',
    'skills': [
      'Art direction',
      'Interactive Design',
      'Motion',
      '3D Design',
    ],
    'images': [
      'BNK',
      'BNK-1',
      'BNK-2',
      'BNK-3',
      'BNK-4',
    ]
  },
  {
    'title': 'BMB Avocats',
    'date': '2019',
    'company': 'Employee at Digital Cover',
    'url': 'https://bmb-avocats.com',
    'skills': [
      'Art direction',
      'Branding & Logo',
      'Photography Direction',
      'Interactive Design',
      'Motion',
    ],
    'images': [
      'BMB',
      'BMB-1',
      'BMB-2',
      'BMB-3',
      'BMB-4',
      'BMB-5',
      'BMB-6',
    ]
  },
  {
    'title': 'Decoceram Carrelage XXL',
    'date': '2019',
    'company': 'Employee at Digital Cover',
    'url': 'https://carrelage-xxl.decoceram.fr',
    'skills': [
      'Art direction',
      'Interactive Design',
      'Motion'
    ],
    'images': [
      'XXL',
      'XXL-1',
      'XXL-2',
      'XXL-3',
      'XXL-4',
    ]
  },	
  {
    'title': 'MJ Ossature',
    'date': '2020',
    'company': 'Employee at Digital Cover',
    'url': 'https://www.mj-ossature-bois.fr',
    'skills': [
      'Art direction',
      'Interactive Design',
      'Motion',
    ],
    'images': [
      'MJO',
      'MJO-1',
      'MJO-2',
      'MJO-3',
      'MJO-4',
    ]
  },
]

export default projects